@charset "UTF-8";
.wrapper {
  overflow: hidden; }

.img-holder {
  position: relative; }

.btn-holder {
  position: absolute;
  top: 70%;
  background-color: #fff;
  opacity: 0.3;
  width: 100%;
  height: 30%; }

.link-btn {
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%); }

.sp-col-right {
  float: left;
  background-color: #f3f8fa; }

/* Primære */
/* Udtoninger af primære */
/* Sekundære */
/* Besked-farver */
/* Ændre */
html {
  margin-left: calc(100vw - 100%); }

@media (min-width: 576px) {
  a.skipnav:focus {
    outline: 4px solid #004e6c; } }

@media (min-width: 768px) {
  .tabnav li a:focus span:not(.sr-only) {
    outline: 4px solid #004e6c; } }

@media (min-width: 992px) {
  .navbar-primary {
    border-bottom: 1px solid #747474; }
  .nav-primary .current span {
    border-bottom: 7px solid #004e6c; }
  .nav-primary .overflow-menu .button-overflow-menu:hover span {
    border-bottom: 7px solid #004e6c; }
  .nav-primary button[aria-expanded=true] span {
    border-bottom: 7px solid #004e6c; }
  .nav-primary .nav-link:hover span {
    border-bottom: 7px solid #004e6c; } }

.button {
  border: 1px solid #00374d; }

.button.button-primary {
  background-color: #004e6c;
  border-color: #00374d; }

.button.button-primary:hover, .button.button-primary.button-hover {
  background-color: #00374d;
  border-color: #00121a; }

.button.button-primary:active, .button.button-primary.button-active {
  background-color: #00121a;
  border-color: #00121a; }

.button.button-primary.focus, .button.button-primary:focus {
  background-color: #004e6c;
  border-color: #00374d; }

.button.button-primary:disabled:hover, .button.button-primary.disabled:hover {
  background-color: #004e6c;
  border-color: #00374d; }

.nav-primary .overflow-menu .button-overflow-menu:focus, .nav-primary .overflow-menu .button-overflow-menu:active {
  outline: 4px solid #004e6c; }

.nav-primary .overflow-menu .overflow-menu-inner .active > a {
  color: #004e6c; }

.search .button-search {
  border-color: #004e6c; }

.sidenav-list li a:hover {
  color: #00374d; }

.sidenav-list li a .sidenav-information {
  color: #004e6c; }

.sidenav-list li.active > a {
  color: #004e6c; }

.sidenav-list > li.current {
  border-left: 4px solid #004e6c; }

.sidenav-list-locked a:hover {
  color: #004e6c; }

#main-content:focus {
  outline: 4px solid #004e6c; }

a:focus, button:focus, input:focus, select:focus, textarea:focus, .focus {
  outline: 4px solid #004e6c; }

a:focus, a.link-focus {
  outline: 4px solid #004e6c; }

.button.button-tertiary {
  border-color: #004e6c; }

.button.button-tertiary:hover, .button.button-tertiary.button-hover {
  border-color: #004e6c; }

.button.button-tertiary.focus, .button.button-tertiary:focus {
  border-color: #004e6c; }

.button.button-tertiary:disabled:hover, .button.button-tertiary.disabled:hover {
  border-color: #004e6c; }

.button.button-small:hover, .button.button-small.button-hover {
  border-color: #004e6c; }

.tag:hover, .tag.tag-hover {
  background-color: #004e6c; }

.tag.focus, .tag:focus {
  border-color: #004e6c;
  outline-color: #004e6c; }

.form-input:focus, .form-input.focus {
  outline: 4px solid #004e6c; }

.form-select:focus, .form-select.focus {
  outline: 4px solid #004e6c; }

.form-checkbox:focus + label::before,
.form-checkbox.focus + label::before {
  outline: 4px solid #004e6c; }

.form-radio:focus + label::before,
.form-radio.focus + label::before {
  box-shadow: 0 0 0 3px #004e6c; }

footer .footer {
  border-top: 1px solid #747474; }

.header .solution-header {
  border-bottom: 1px solid #bfbfbf; }

.header .solution-header.header-end {
  border-bottom: 1px solid #bfbfbf; }

.header .portal-header {
  background-color: #fff; }

.inline-radio {
  display: inline-block;
  margin-right: 10px; }

.button-link {
  background: none !important;
  border: none;
  padding: 0 !important;
  color: #06324b;
  text-decoration: underline;
  cursor: pointer; }

.pointer {
  cursor: pointer; }

.popover {
  display: inline-block; }

.popover:hover {
  position: relative; }

.popover:hover::after {
  font-weight: 400;
  color: #1a1a1a;
  display: inline-block;
  background-color: #ffffff;
  padding: .3rem .6rem;
  content: attr(data-tooltip);
  position: absolute;
  bottom: 100%;
  width: 500px;
  transform: translate(-50%, 0);
  border: 1px solid #747474;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
  border-radius: 4px; }

.header .portal-header .logo {
  background-image: 'url(../img/logo-uvm.svg)';
  height: 4.8rem; }
