.wrapper {
    overflow: hidden;
    display: flex;
    justify-content: space-between;
}

.sp-col-tekst {
    float: left;
    min-height: 430px;
    padding: 15px;
}

.sp-col-res {
    padding: 15px;
}

.sp-res-tal {
    color: #fff;
    font-weight: 600;
    font-size: 24px;
}
.sp-res-txt {
    color: #fff;
    font-weight: 600;
    font-size: 18px;
}

.position-rel {
    position: relative;
    width: 100%;
}

.position-bottom {
    position: absolute;
    bottom: 10px;
    width: auto;
}

.havblaa-4 {
    background-color: #b8d6df;
}

.antracitgraa-4 {
    background-color: #c4cbcf;
}

.groen-4 {
    background-color: #b8d3cd;
}