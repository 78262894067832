.sp-tab-2 {
    margin-left: 20px;
}

.sp-indb-container {
    overflow: hidden;
    display: flex;
}

.sp-indb-sidenav {
    float: left;
}
 
.sp-indb-content {
    float: left;
    margin-left: 20px;
}

.sp-elevdatacell {
    margin: 10px 0px 10px 0px;
}