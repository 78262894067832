

.forside-container {
    display: flex;
}

.img-holder {
    position: relative;
}

.btn-holder {
    position: absolute;
    top: 70%;
    background-color: #fff;
    opacity: 0.3;
    width: 100%;
}

.link-btn {
    position: absolute;
    top: 85%;
    left: 50%;
    transform: translate(-50%, -50%);
}


.sp-col-left, .sp-col-right {
    float: left;
}

.sp-col-right {
    background-color: #f3f8fa;
    margin-left: 35px;
}
.sp-col-left {
    position: relative;
}