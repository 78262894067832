.left {
    padding:20px;
    float:left;
    width:160px; 
  }
  
  .main {
    padding:20px;
    float:left;
  }
